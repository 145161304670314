import React from 'react'

export default function InputRadio({ data, register, ErrorMessage, errors }) {
  return (
    <div className={`form-group clearfix`}>
      <label>
        {data.label}
        {data.isMandatory === '1' && (
          <span className="text-danger font-weight-bold"> *</span>
        )}
      </label>
      <div className="row">
        {data.valueList.map((radio, index) => {
          return (
            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-6">
              <div className="icheck-info d-inline" key={radio.dataId}>
                <input
                  {...register}
                  defaultChecked={radio.selected === 'true'}
                  className="custom-control-input"
                  type={data.type}
                  id={radio.dataId + index}
                  value={radio.dataId}
                />
                <label htmlFor={radio.dataId + index}>{radio.dataDesc}</label>
              </div>
            </div>
          )
        })}{' '}
      </div>
    </div>
  )
}
