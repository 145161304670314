import React from 'react'
import { useSelector } from 'react-redux'
import { getAllReport } from '../Services/ReportService'
import Panel from '../Components/Report/Panel'
import { useQuery } from 'react-query'

const Report = () => {
  const menu = useSelector((state) => state.menu)
  const param = { menuId: menu.activeMenuId }

  const { data, isLoading, isError } = useQuery('report', () =>
    getAllReport(param)
  )

  if (isLoading) return <div>Loading...</div>
  if (isError)
    return window.Swal.fire('Kesalahan', `Silahkan muat ulang halaman`, 'error')
  if (!data.data.panel)
    return window.Swal.fire('Kesalahan', `Silahkan muat ulang halaman`, 'error')
  return (
    <div>
      {data.data.panel
        ? data.data.panel.map((item) => (
            <Panel item={item} key={item.form} id={item.form} />
          ))
        : null}
    </div>
  )
}

export default Report
