import ButtonAction from '../Button/ButtonAction'
import ButtonModal from '../Button/ButtonModal'
import ButtonDropdownTopAction from '../Table/TopAction/ButtonDropdownTopAction'

function ButtonType({
  buttonItem,
  gridItem,
  setDataQuery,
  setFilterData,
  handleSubmit,
}) {
  return (
    <>
      {buttonItem.type === 'button' && (
        <ButtonAction
          actionItem={buttonItem}
          handleSubmit={handleSubmit}
          type="button"
        />
      )}
      {buttonItem.type === 'buttonDropdown' && (
        <ButtonDropdownTopAction
          icon={buttonItem.icon}
          label={buttonItem.label}
          item={buttonItem.item}
          className={buttonItem.className + ' dropdown-toggle'}
        />
      )}
      {buttonItem.type === 'buttonModal' && (
        <ButtonModal
          icon={buttonItem.icon}
          label={buttonItem.label}
          dataTarget={buttonItem.dataTarget}
          className={buttonItem.className}
          gridItem={gridItem}
          setDataQuery={setDataQuery}
          setFilterData={setFilterData}
          topAction={buttonItem}
        />
      )}
    </>
  )
}

export default ButtonType
