import { ErrorMessage } from '@hookform/error-message'
import DropdownSelect from './Input/DropdownSelect'
import InputCommon from './Input/InputCommon'
import InputDate from './Input/InputDate'
import InputFile from './Input/InputFile'
import Flowchart from '../../Pages/Flowchart'
import InputRadio from './Input/InputRadio'
import InputTextboxWithButton from './Input/InputTextboxWithButton'
import InputDatetime from './Input/InputDatetime'
import InputTime from './Input/InputTime'
import TableGrid from '../../Pages/TableGrid'
import InputTextareaWithCheckbox from './Input/InputTextareaWithCheckbox'
import CheckboxCopyValue from './Input/CheckboxCopyValue'
import BoxForm from './Box/BoxForm'
import ReportForm from '../AutoLayout/Chart/ReportForm'
import ChartForm from '../AutoLayout/Chart/ChartForm'

/* 
  note:
  # FieldType => dipakai untuk return komponen sesuai tipe field
  fieldItem: item per field (hasil looping listField)
  fieldList: listField array
  register, setValue, resetField, errors: dari react-hook-form
  activeTabId: (optional) dipake untuk grid dalam tab 
*/
function FieldType({
  panel = [],
  fieldItem,
  fieldList,
  register,
  setValue,
  getValues,
  resetField,
  errors,
  activeTabId,
  watch,
  clearErrors,
  control,
}) {
  return (
    <>
      {['textbox', 'email'].includes(fieldItem.type) && (
        <div className={`col-md-${fieldItem.width || '12'}`}>
          <div className="form-group">
            <InputCommon
              label={fieldItem.label}
              fieldItem={fieldItem}
              type="text"
              id={fieldItem.id}
              parent={fieldItem.reference?.parent || []}
              child={fieldItem.reference?.child || []}
              defaultValue={fieldItem.value}
              rule={fieldItem.rule}
              isMandatory={fieldItem.isMandatory == '1' ? true : false}
              getValues={getValues}
              setValue={setValue}
              watch={watch}
              resetField={resetField}
              hide={fieldItem.hide == '1' || false}
              readOnly={fieldItem.isReadOnly == '1' || false}
              register={register(fieldItem.id, {
                required:
                  fieldItem.isMandatory === '1'
                    ? `${fieldItem.label} harus diisi`
                    : false,
                pattern:
                  fieldItem.type === 'email'
                    ? {
                        value:
                          /^(?=[^@]{2,}@)(?=[^\.]{2,}\.)([\w\.-]*[a-zA-Z0-9_]@(?=.{2,}\.[^.]*$)[\w\.-]*[a-zA-Z0-9]\.[a-zA-Z][a-zA-Z\.]*[a-zA-Z])$/,
                        message: `${fieldItem.label} harus sesuai`,
                      }
                    : false,
              })}
            />
            <ErrorMessage
              errors={errors}
              name={fieldItem.id}
              as="div"
              style={{ color: 'red', marginTop: '5px' }}
            />
          </div>
        </div>
      )}
      {fieldItem.type === 'textarea' && (
        <div className={`col-md-${fieldItem.width || '12'}`}>
          <div className="form-group">
            <label>{fieldItem.label}</label>
            <textarea
              className="form-control"
              rows="3"
              placeholder={`Masukkan ${fieldItem.label}`}
              {...register(fieldItem.id, {
                required:
                  fieldItem.isMandatory === '1'
                    ? `${fieldItem.label} harus diisi`
                    : false,
              })}
            ></textarea>
            <ErrorMessage
              errors={errors}
              name={fieldItem.id}
              as="div"
              style={{ color: 'red', marginTop: '5px' }}
            />
          </div>
        </div>
      )}
      {fieldItem.type === 'radio' && (
        <div className={`col-md-${fieldItem.width}`}>
          <InputRadio
            data={fieldItem}
            id={fieldItem.id}
            isReadOnly={fieldItem.isReadOnly === '1' || false}
            hide={fieldItem.hide === '' || false}
            register={register(fieldItem.id, {
              required:
                fieldItem.isMandatory === '1'
                  ? `Pilih ${fieldItem.label} terlebih dulu`
                  : false,
            })}
          />
          <ErrorMessage
            errors={errors}
            name={fieldItem.id}
            as="div"
            style={{ color: 'red', marginTop: '5px' }}
          />
        </div>
      )}
      {fieldItem.type === 'dropdown' && (
        <div className={`col-md-${fieldItem.width || '12'}`}>
          <div className="form-group">
            <DropdownSelect
              label={fieldItem.label}
              id={fieldItem.id}
              panel={panel}
              listField={fieldList}
              fieldItem={fieldItem}
              parent={fieldItem.reference?.parent || []}
              child={fieldItem.reference?.child || []}
              resetField={resetField}
              readOnly={fieldItem.isReadOnly === '1' || false}
              getValues={getValues}
              setValue={setValue}
              hide={fieldItem.hide === '1' || false}
              defaultValue={fieldItem.valueList}
              condition={fieldItem.condition || []}
              isMandatory={fieldItem.isMandatory === '1' || false}
              isReadOnly={fieldItem.isReadOnly === '1' || false}
              register={register(fieldItem.id, {
                required:
                  fieldItem.isMandatory === '1'
                    ? `${fieldItem.label} harus diisi`
                    : false,
              })}
            />
            <ErrorMessage
              errors={errors}
              name={fieldItem.id}
              as="div"
              style={{ color: 'red', marginTop: '5px' }}
            />
          </div>
        </div>
      )}
      {fieldItem.type === 'datetime' && (
        <div className={`col-md-${fieldItem.width || '12'}`}>
          <div className="form-group">
            <InputDatetime
              child={fieldItem.reference?.child || []}
              defaultValue={fieldItem.value}
              hide={fieldItem.hide === '1' || false}
              id={fieldItem.id}
              isMandatory={fieldItem.isMandatory === '1' ? true : false}
              label={fieldItem.label}
              readOnly={fieldItem.isReadOnly === '1' || false}
              register={register(fieldItem.id, {
                required:
                  fieldItem.isMandatory === '1'
                    ? `${fieldItem.label} harus diisi`
                    : false,
              })}
              setValue={setValue}
              view={fieldItem.view}
              watch={watch}
            />
            <ErrorMessage
              errors={errors}
              name={fieldItem.id}
              as="div"
              style={{ color: 'red', marginTop: '5px' }}
            />
          </div>
        </div>
      )}
      {fieldItem.type === 'date' && (
        <div className={`col-md-${fieldItem.width || '12'}`}>
          <div className="form-group">
            <InputDate
              child={fieldItem.reference?.child || []}
              defaultValue={fieldItem.value}
              hide={fieldItem.hide === '1' || false}
              id={fieldItem.id}
              isMandatory={fieldItem.isMandatory === '1' ? true : false}
              label={fieldItem.label}
              register={register(fieldItem.id, {
                required:
                  fieldItem.isMandatory === '1'
                    ? `${fieldItem.label} harus diisi`
                    : false,
              })}
              setValue={setValue}
              view={fieldItem.view}
              watch={watch}
            />
            <ErrorMessage
              errors={errors}
              name={fieldItem.id}
              as="div"
              style={{ color: 'red', marginTop: '5px' }}
            />
          </div>
        </div>
      )}
      {fieldItem.type === 'time' && (
        <div className={`col-md-${fieldItem.width || '12'}`}>
          <div className="form-group">
            <InputTime
              child={fieldItem.reference?.child || []}
              defaultValue={fieldItem.value}
              hide={fieldItem.hide === '1' || false}
              id={fieldItem.id}
              isMandatory={fieldItem.isMandatory === '1' ? true : false}
              label={fieldItem.label}
              register={register(fieldItem.id, {
                required:
                  fieldItem.isMandatory === '1'
                    ? `${fieldItem.label} harus diisi`
                    : false,
              })}
              setValue={setValue}
              view={fieldItem.view}
              watch={watch}
            />
            <ErrorMessage
              errors={errors}
              name={fieldItem.id}
              as="div"
              style={{ color: 'red', marginTop: '5px' }}
            />
          </div>
        </div>
      )}
      {fieldItem.type === 'file' && (
        <div className={`col-md-${fieldItem.width || '12'}`}>
          <InputFile fieldItem={fieldItem} register={register(fieldItem.id)} />
        </div>
      )}
      {fieldItem.type === 'grid' && (
        <div className="w-100">
          <TableGrid gridItem={fieldItem} activeTabId={activeTabId} />
        </div>
      )}
      {fieldItem.type === 'flowchart' && (
        <div className="mx-1 w-100">
          <Flowchart flowchart={fieldItem} />
        </div>
      )}
      {fieldItem.type === 'textboxwithbutton' && (
        <div className={`col-md-${fieldItem.width || '12'}`} key={fieldItem.id}>
          <div className="from-group">
            <InputTextboxWithButton
              button={fieldItem.button}
              child={fieldItem.reference?.child || []}
              clearErrors={clearErrors}
              defaultValue={fieldItem.value}
              flag={fieldItem.flag}
              getValues={getValues}
              hide={fieldItem.hide === '1' || false}
              id={fieldItem.id}
              isMandatory={fieldItem.isMandatory === '1' ? true : false}
              label={fieldItem.label}
              parent={fieldItem.reference?.parent || []}
              path={fieldItem.path}
              readOnly={fieldItem.isReadOnly === '1' || false}
              register={register(fieldItem.id, {
                required:
                  fieldItem.isMandatory === '1'
                    ? `${fieldItem.label} harus diisi`
                    : false,
              })}
              resetField={resetField}
              setValue={setValue}
              width={fieldItem.width}
            />
            <ErrorMessage
              errors={errors}
              name={fieldItem.id}
              as="div"
              style={{ color: 'red', marginTop: '5px' }}
            />
          </div>
        </div>
      )}
      {fieldItem.type === 'textareawithcheckbox' && (
        <div className={`col-md-${fieldItem.width || '12'}`} key={fieldItem.id}>
          <div className="form-group">
            <InputTextareaWithCheckbox
              child={fieldItem.reference?.child || []}
              checkbox={fieldItem.checkbox}
              defaultValue={fieldItem.value}
              flag={fieldItem.flag}
              getValues={getValues}
              hide={fieldItem.hide === '1' || false}
              id={fieldItem.id}
              isMandatory={fieldItem.isMandatory === '1' ? true : false}
              label={fieldItem.label}
              parent={fieldItem.reference?.parent || []}
              path={fieldItem.path}
              readOnly={fieldItem.isReadOnly === '1' || false}
              register={register(fieldItem.id, {
                required:
                  fieldItem.isMandatory === '1'
                    ? `${fieldItem.label} harus diisi`
                    : false,
              })}
              resetField={resetField}
              setValue={setValue}
              watch={watch}
              width={fieldItem.width}
            />
            <ErrorMessage
              errors={errors}
              name={fieldItem.id}
              as="div"
              style={{ color: 'red', marginTop: '5px' }}
            />
          </div>
        </div>
      )}
      {fieldItem.type === 'checkboxcopyvalue' && (
        <div className={`col-md-${fieldItem.width || '12'}`} key={fieldItem.id}>
          <div className="form-group">
            <CheckboxCopyValue
              child={fieldItem.reference?.child || []}
              control={control}
              defaultValue={fieldItem.value}
              flag={fieldItem.flag}
              getValues={getValues}
              hide={fieldItem.hide === '1' || false}
              id={fieldItem.id}
              isMandatory={fieldItem.isMandatory === '1' ? true : false}
              label={fieldItem.label}
              parent={fieldItem.reference?.parent || []}
              path={fieldItem.path}
              readOnly={fieldItem.isReadOnly === '1' || false}
              register={register(fieldItem.id, {
                required:
                  fieldItem.isMandatory === '1'
                    ? `${fieldItem.label} harus diisi`
                    : false,
              })}
              resetField={resetField}
              setValue={setValue}
              watch={watch}
              width={fieldItem.width}
            />
            <ErrorMessage
              errors={errors}
              name={fieldItem.id}
              as="div"
              style={{ color: 'red', marginTop: '5px' }}
            />
          </div>
        </div>
      )}
      {fieldItem.type === 'box' && (
        <div className={`col-${fieldItem.width}`}>
          <BoxForm
            level={fieldItem.dataset[0].level}
            fieldId={fieldItem.dataset[0].fieldId}
            path={fieldItem.dataset[0].path}
            width={fieldItem.width}
          />
        </div>
      )}
      {fieldItem.type === 'chart' && (
        <div className={`col-${fieldItem.width}`}>
          <ReportForm
            level={fieldItem.dataset[0].level}
            fieldId={fieldItem.id}
            path={fieldItem.dataset[0].path}
          />
        </div>
      )}
    </>
  )
}

export default FieldType
