import { ErrorMessage } from '@hookform/error-message'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { AuthLogin } from '../Services/AuthService'
import Logo from './Logo'

function Login() {
  // load login
  const [isLoading, setLoading] = useState(false)
  // show hide password
  const [passwordShown, setPasswordShown] = useState(false)
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true)
  }

  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'onChange' })

  const handleLogin = ({ userId, password }) => {
    setLoading(true)
    AuthLogin(userId, password)
      .then((res) => {
        if (res.response.status == 1) {
          localStorage.setItem('token', res.response.accessToken)
          window.location = '/auth'
        } else {
          setLoading(false)
          window.Swal.fire('Kesalahan', res.response.message, 'error')
        }
      })
      .catch((err) => {
        setLoading(false)
        window.Swal.fire('Peringatan', 'Kesalahan pada server', 'error')
      })
  }

  return (
    <div className="login-page">
      <div className="login-box">
        <div className="card">
          <div className="card-body login-card-body rounded">
            <div className="login-logo">
              <Logo />
              <hr></hr>
              <a href="#">
                <b>Digital Lending</b>
              </a>
            </div>
            <p className="login-box-msg">Sign in to start your application</p>
            <form onSubmit={handleSubmit(handleLogin)}>
              <div className="mb-3">
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="User ID"
                    {...register('userId', {
                      required: 'User ID required',
                    })}
                    autoComplete="off"
                  />
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <span className="fas fa-user" />
                    </div>
                  </div>
                </div>
                <ErrorMessage
                  errors={errors}
                  name="userId"
                  as={<span className="text-danger text-xs"></span>}
                />
              </div>
              <div className="mb-3">
                <div className="input-group">
                  <input
                    type={passwordShown ? 'text' : 'password'}
                    className="form-control"
                    placeholder="Password"
                    {...register('password', {
                      required: 'Password required',
                    })}
                  />
                  <div className="input-group-append">
                    <div className="input-group-text">
                      {!passwordShown && (
                        <span
                          className="fas fa-lock"
                          onClick={togglePasswordVisiblity}
                        />
                      )}{' '}
                      {passwordShown && (
                        <span
                          className="fas fa-unlock"
                          onClick={togglePasswordVisiblity}
                        />
                      )}{' '}
                    </div>
                  </div>
                </div>
                <ErrorMessage
                  errors={errors}
                  name="password"
                  as={<span className="text-danger text-xs"></span>}
                />
              </div>
              <div className="row float-right">
                <div className="col-12 float-end">
                  {!isLoading && (
                    <button type="submit" className="btn btn-info btn-block">
                      Sign In
                    </button>
                  )}
                  {isLoading && (
                    <button
                      type="submit"
                      disabled
                      className="btn btn-info btn-block"
                    >
                      <i className="fas fa-spinner fa-spin"></i>
                      Sign In
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login
