import React, { useMemo, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useNavigate } from 'react-router-dom'
import { getMenu } from '../Services/MenuService'
import { setMenuSlice } from '../Store/Menu/menuSlice'

function SideBar() {
  const dispatch = useDispatch()
  // redux state
  const userId = useSelector((state) => state.user.id)
  const activeMenuId = useSelector((state) => state.menu.activeMenuId)
  const activeModuleId = useSelector((state) => state.user.activeModule.id)
  const activeRoleId = useSelector((state) => state.user.activeRole.id)
  // state
  const [menu, setMenu] = useState('')
  const [searchSidebarValue, setSearchSidebarValue] = useState('')
  const navigate = useNavigate()

  useEffect(() => {
    if (activeModuleId && activeRoleId) {
      // handle menu data
      getMenu(userId, activeModuleId, activeRoleId)
        .then((res) => {
          if (res.data.status == '1') {
            setMenu(res.data)
          }
        })
        .catch((e) =>
          window.Swal.fire('Kesalahan', e.response.message, 'error')
        )
    }
  }, [activeModuleId, activeRoleId])

  const filteredMenu = useMemo(() => {
    if (menu) {
      // get parent menu by searched value
      const parentMenu = menu.data.filter((data) => {
        const menuLower = data.menuDesc.toLowerCase()
        const searchedValueLower = searchSidebarValue.toLowerCase()
        return menuLower.includes(searchedValueLower)
      })
      // get child menu by searched value
      const childMenu = menu.data.filter((data) => {
        const searchedValueLower = searchSidebarValue.toLowerCase()
        if (data.child) {
          return data.child.some((child) =>
            child.menuDesc.toLowerCase().includes(searchedValueLower)
          )
        }
      })
      // combine parent & child menu
      return [...new Set([...parentMenu, ...childMenu])]
    }
  }, [menu, searchSidebarValue]) // trigger on [menu, searchSidebarValue] changed

  useEffect(() => {
    window.$('[data-widget="treeview"]').Treeview('init')
  })

  const handleMenuClick = (data) => {
    const hasChild = data.child || false
    if (!hasChild) {
      const { menuDesc, menuId, trackId } = data
      // set active menu
      dispatch(setMenuSlice({ menuId, trackId, menuDesc }))
    }
  }

  return (
    <aside className="main-sidebar elevation-4 sidebar-light-info">
      <a
        href="#"
        className="brand-link d-flex align-items-center justify-content-center"
        style={{ height: '52px' }}
      >
        <img
          src={process.env.PUBLIC_URL + '/images/cloufina.svg'}
          alt="windy-logo"
          style={{ height: '30px' }}
          onClick={() => navigate('/dashboard')}
        />
      </a>

      <div className="sidebar">
        <nav className="mt-2">
          <div className="px-3 mb-3">
            <input
              className="form-control form-control-sidebar"
              type="text"
              placeholder="Search"
              onChange={(e) => setSearchSidebarValue(e.target.value)}
            />
          </div>
          <ul
            className="nav nav-pills nav-sidebar flex-column"
            data-widget="treeview"
            data-accordion="true"
          >
            {filteredMenu?.map((data, index) => {
              return (
                <li className="nav-item" key={index}>
                  {/* parent menu */}
                  <NavLink
                    to={data.child ? '#' : data.path}
                    onClick={() => handleMenuClick(data)}
                    className={() => {
                      if (data.menuId === activeMenuId) {
                        return 'nav-link active'
                      } else {
                        return 'nav-link'
                      }
                    }}
                  >
                    {data.icon !== '' ? (
                      <i className={'nav-icon ' + data.icon}></i>
                    ) : (
                      <i className={'nav-icon fal fa-circle'}></i>
                    )}
                    <p>
                      {data.menuDesc}
                      {data.child && (
                        <i className="right fas fa-angle-left"></i>
                      )}
                    </p>
                  </NavLink>
                  {/* child menu */}
                  {data.child && (
                    <ul className="nav nav-treeview">
                      {data.child.map((child, index) => (
                        <li className="nav-item" key={index}>
                          <NavLink
                            to={data.path}
                            onClick={() => handleMenuClick(child)}
                            className={() => {
                              if (child.menuId === activeMenuId) {
                                return 'nav-link active'
                              } else {
                                return 'nav-link'
                              }
                            }}
                          >
                            {child.icon !== '' ? (
                              <i className={'nav-icon ' + child?.icon}></i>
                            ) : (
                              <i className={'nav-icon fal fa-circle'}></i>
                            )}
                            <p>{child.menuDesc}</p>
                          </NavLink>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              )
            })}
          </ul>
        </nav>
      </div>
    </aside>
  )
}

export default SideBar
