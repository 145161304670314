import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
// prettier-ignore
import { handleGetListData, handleGetListStructure } from '../Utils/TableUtils'
import TableComponent from '../Components/Table/TableComponent'

function TableList() {
  // state
  const [dataQuery, setDataQuery] = useState()
  const [structures, setStructures] = useState({})
  const [columnVisibility, setColumnVisibility] = useState({ ap_regno: false })
  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  })
  // redux state
  const menu = useSelector((state) => state.menu)
  const user = useSelector((state) => state.user)
  const filtering = useSelector((state) => state.list.filtering)

  // get structure
  useEffect(() => {
    if (menu.activeMenuId !== '') {
      setPagination({ pageIndex: 0, pageSize: 10 })
      handleGetListStructure(user, menu, setStructures)
    }
  }, [menu.activeMenuId])

  // get data
  useEffect(() => {
    if (menu.activeMenuId !== '') {
      const payload = {
        userId: user.id,
        menuId: menu.activeMenuId,
        moduleId: user.activeModule.id,
        roleId: user.activeRole.id,
        filtering: filtering?.length ? filtering : [{ id: '', value: '' }],
        pagination: {
          pageIndex: pageIndex + 1,
          perPage: pageSize,
        },
      }
      handleGetListData(payload, setDataQuery)
    }
  }, [menu.activeMenuId, pageIndex, pageSize])

  return (
    <TableComponent
      dataQuery={dataQuery}
      structures={structures}
      columnVisibility={columnVisibility}
      pageIndex={pageIndex}
      pageSize={pageSize}
      setDataQuery={setDataQuery}
      setColumnVisibility={setColumnVisibility}
      setPagination={setPagination}
    />
  )
}

export default TableList
