export const list = [
  {
    noData: '1',
    SDE_002: '000261002023',
    SDE_003: '',
    SDE_004: '',
    SDE_005: '',
    SDE_006: '',
  },
  {
    noData: '2',
    SDE_002: '000271002023',
    SDE_003: '',
    SDE_004: '',
    SDE_005: '',
    SDE_006: '',
  },
  {
    noData: '3',
    SDE_002: '000281002023',
    SDE_003: 'test',
    SDE_004: '01',
    SDE_005: '1234567891234567',
    SDE_006: '',
  },
  {
    noData: '4',
    SDE_002: '000291002023',
    SDE_003: 'test lagi',
    SDE_004: '01',
    SDE_005: '1234567891234567',
    SDE_006: '839928837822222',
  },
  {
    noData: '5',
    SDE_002: '000301002023',
    SDE_003: '',
    SDE_004: '',
    SDE_005: '',
    SDE_006: '',
  },
  {
    noData: '6',
    SDE_002: '000311002023',
    SDE_003: '',
    SDE_004: '',
    SDE_005: '',
    SDE_006: '',
  },
  {
    noData: '7',
    SDE_002: '000321002023',
    SDE_003: '',
    SDE_004: '',
    SDE_005: '',
    SDE_006: '',
  },
  {
    noData: '8',
    SDE_002: '000331002023',
    SDE_003: 'test lagi lagi',
    SDE_004: '01',
    SDE_005: '1234567891234567',
    SDE_006: '839928837822222',
  },
  {
    noData: '9',
    SDE_002: '000341002023',
    SDE_003: '',
    SDE_004: '',
    SDE_005: '',
    SDE_006: '',
  },
  {
    noData: '10',
    SDE_002: '000351002023',
    SDE_003: '',
    SDE_004: '',
    SDE_005: '',
    SDE_006: '',
  },
]
