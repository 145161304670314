import React, { useEffect } from 'react'
import { NumericFormat } from 'react-number-format'
import { getReference } from '../../../Services/AutoLayoutService'

function InputFormat({ id, register, watch, rule, setValue, handleOnBlur }) {
  const watchHiddenInput = watch(id, 0)
  return (
    <>
      <input type="hidden" id={id} {...register} />
      <NumericFormat
        value={+watchHiddenInput}
        type="text"
        allowNegative={rule === 'numericAbsolute' ? false : true}
        className="form-control"
        thousandsGroupStyle="thousand"
        thousandSeparator=","
        onBlur={handleOnBlur}
        onValueChange={(values) => {
          setValue(id, values.value)
          const hiddenInput = document.getElementById(id)
          hiddenInput.dispatchEvent(new Event('change'))
        }}
      />
    </>
  )
}

export default function InputCommon({
  fieldItem,
  label,
  id,
  isMandatory,
  register,
  control,
  setValue,
  resetField,
  getValues,
  watch,
  hide,
  parent,
  child,
  rule,
  defaultValue,
  ...props
}) {
  const handleOnBlur = () => {
    // if has parent, only check for verification (not get value)
    if (parent.length) {
      const payload = { referenceName: id, param: [] }
      // set current value to payload
      const currentValue = window.$('#' + id).val()
      payload.param.push({ id: id, value: currentValue })
      // set parent value to payload
      parent.forEach((parentId) => {
        const parentValue = getValues(parentId)
        payload.param.push({ id: parentId, value: parentValue })
      })
      console.log('get rate verification by rate & tenor', payload)
      // handle message
      window.Swal.fire(
        'Data tidak sesuai!',
        'Rate tidak sesuai dengan tenor',
        'error'
      )
    }
    // if has child
    if (child.length) {
      child.forEach((childId) => {
        // set payload
        const payload = { referenceName: childId, param: [] }
        const currentValue = window.$('#' + id).val()
        payload.param.push({ id: id, value: currentValue })
        // get nama by nik (payload)
        getReference(payload).then((res) => {
          console.log(res.data.data)
          // set value to child
          // setValue(childId, res.data.data[0].value)
        })
      })
    }
  }

  useEffect(() => {
    defaultValue && setValue(id, defaultValue)
    if (parent && parent.length) {
      parent.forEach((parentId) => {
        const parentEl = document.getElementById(parentId)
        if (parentEl) {
          let eventType = parentEl.tagName === 'INPUT' ? 'input' : 'change'
          parentEl.addEventListener(eventType, () => {
            // reset current field value
            resetField(id)
            const currentFieldEl = document.getElementById(id)
            currentFieldEl.dispatchEvent(new Event('change'))
            // reset child value
            if (child.length) {
              child.forEach((childId) => {
                resetField(childId)
                const childEl = document.getElementById(childId)
                childEl.dispatchEvent(new Event('change'))
              })
            }
          })
        }
      })
    }
  }, [])

  return (
    <>
      <label
        className={`${hide ? 'hidden' : ''}`}
        onClick={() => console.log(fieldItem)}
      >
        {label}
        {isMandatory && (
          <span className="text-danger font-weight-bold"> *</span>
        )}
      </label>
      {rule === 'numericAbsolute' || rule === 'floatAbsolute' ? (
        <InputFormat
          id={id}
          rule={rule}
          register={register}
          watch={watch}
          setValue={setValue}
          handleOnBlur={handleOnBlur}
        />
      ) : (
        <input
          id={id}
          className={`form-control form-control-sm ${hide ? 'hidden' : ''}`}
          onBlur={handleOnBlur}
          defaultValue={defaultValue || ''}
          {...register}
          {...props}
        />
      )}
      {/* <small>
        id: {id}. parent: {JSON.stringify(parent)}. child:{' '}
        {JSON.stringify(child)}. rule: {rule}. default: {defaultValue}
      </small> */}
    </>
  )
}
