import { useMemo, useState } from 'react'
// prettier-ignore
import { createColumnHelper, flexRender, getCoreRowModel, useReactTable, } from '@tanstack/react-table'
import classNames from 'classnames'
import { handleStructureHeader } from '../../Utils/TableUtils'
import { SkeletonTable } from '../AutoLayout/Skeleton'
import TopAction from './TopAction'

function TableComponent({
  dataQuery,
  structures,
  columnVisibility,
  pageIndex,
  pageSize,
  gridItem,
  setDataQuery,
  setColumnVisibility,
  setPagination,
}) {
  const columnHelper = createColumnHelper()
  // state
  const [rowSelection, setRowSelection] = useState({})

  // handle structure
  const columns = useMemo(() => {
    return handleStructureHeader(
      structures,
      columnHelper,
      gridItem,
      setDataQuery
    )
  }, [structures])

  // handle pagination
  const pagination = useMemo(
    () => ({ pageIndex, pageSize }),
    [pageIndex, pageSize]
  )

  // table option
  const table = useReactTable({
    data: dataQuery?.rows ?? [],
    columns: columns ?? [],
    pageCount: dataQuery?.pageCount ?? -1,
    state: {
      columnVisibility,
      pagination,
      rowSelection,
    },
    onColumnVisibilityChange: setColumnVisibility,
    onPaginationChange: setPagination,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    debugTable: false,
  })

  return (
    <>
      {!dataQuery ? (
        <SkeletonTable />
      ) : (
        <div>
          {structures?.topAction?.length > 0 && (
            <TopAction
              structures={structures}
              setDataQuery={setDataQuery}
              gridItem={gridItem}
            />
          )}
          <div className="table-responsive">
            <table className="bg-white table table-sm table-bordered table-hover dataTable dtr-inline">
              <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => {
                      return (
                        <th
                          className="text-center"
                          key={header.id}
                          colSpan={header.colSpan}
                        >
                          {header.isPlaceholder ? null : (
                            <div>
                              {flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                            </div>
                          )}
                        </th>
                      )
                    })}
                  </tr>
                ))}
              </thead>
              <tbody>
                {table.getRowModel().rows.map((row) => {
                  return (
                    <tr key={row.id}>
                      {row.getVisibleCells().map((cell) => {
                        return (
                          <td key={cell.id}>
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </td>
                        )
                      })}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
          <section className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <div className="pr-2 text-sm">
                Page {table.getState().pagination.pageIndex + 1} of{' '}
                {table.getPageCount()}
              </div>
              <select
                className="form-control form-control-sm"
                style={{ width: 90 }}
                value={table.getState().pagination.pageSize}
                onChange={(e) => {
                  table.setPageSize(Number(e.target.value))
                }}
              >
                {[10, 25, 50, 75, 100].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </div>
            <div className="pagination pagination-sm justify-content-end">
              <div
                className={classNames('page-item', {
                  disabled: !table.getCanPreviousPage(),
                })}
                onClick={() => table.previousPage()}
              >
                <button
                  type="button"
                  className="page-link"
                  tabIndex="-1"
                  aria-disabled="true"
                >
                  Previous
                </button>
              </div>
              <div
                className={classNames('page-item', {
                  disabled: !table.getCanNextPage(),
                })}
                onClick={() => table.nextPage()}
              >
                <button type="button" className="page-link">
                  Next
                </button>
              </div>
            </div>
          </section>
        </div>
      )}
    </>
  )
}

export default TableComponent
