import { useState } from 'react'
import { useForm } from 'react-hook-form'
import FieldType from '../Components/AutoLayout/FieldType'
import Panel from '../Components/AutoLayout/Panel'
import ExportDocxTester from '../Components/Tester/ExportDocxTester'
import FieldConditionTester from '../Components/Tester/FieldConditionTester'
import TableTester from '../Components/Tester/TableTester'
import { condition } from '../Data/Field/condition'
import { panelCondition } from '../Data/Panel/condition'
// import { listField } from '../Data/Field/response'

function Tester({
  defaultValue = 10000,
  rule = 'numericAbsolute',
  hide = false,
}) {
  const {
    handleSubmit,
    register,
    unregister,
    formState: { errors },
    setValue,
    getValues,
    clearErrors,
    watch,
    resetField,
  } = useForm()
  const [show, setShow] = useState(true)

  const unregisterField = () => {
    setShow(false)
    unregister('coba')
  }

  console.log('field error', errors)

  const onSubmit = (data) => {
    console.log(data)
  }

  return (
    <div className="container">
      <hr />
      <FieldConditionTester />
      <hr />
      <TableTester />
      <hr />
      <ExportDocxTester />
      <hr />
      <form onSubmit={handleSubmit(onSubmit)}>
        {panelCondition.panel.map((panel) => (
          <Panel id={panel.panelId} isLastIndex={false} name={panel.panelName}>
            <>
              {panel.listField.map((fieldItem) => (
                <FieldType
                  key={fieldItem.id}
                  panel={panelCondition.panel}
                  fieldItem={fieldItem}
                  fieldList={panel.listField}
                  register={register}
                  setValue={setValue}
                  getValues={getValues}
                  clearErrors={clearErrors}
                  resetField={resetField}
                  errors={errors}
                  // activeTabId={activeTabId}
                  watch={watch}
                />
              ))}
            </>
          </Panel>
        ))}
        <button type="submit">submit</button>
      </form>
      <hr />
    </div>
  )
}

export default Tester
