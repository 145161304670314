import React, { useState } from 'react'

/* 
  note:
  isLastIndex: remove margin class (mb-5) for last index panel
  children: panel content 
*/
function Panel({ id, isLastIndex, name, children }) {
  const [open, setOpen] = useState(true)

  return (
    <div
      className={`${isLastIndex ? '' : 'mb-5'} card card-info collapse-card`}
      id={id}
    >
      <div className="rounded-top bg-info text-white p-0 d-flex align-items-center justify-content-between">
        <h3 className="card-title ml-3">{name}</h3>
        <div className="card-tools">
          <button
            data-card-widget="collapse"
            type="button"
            className="btn btn-tool text-white"
            onClick={() => (!open ? setOpen(true) : setOpen(false))}
          >
            <i className={open ? 'fas fa-minus' : 'fas fa-plus'}></i>
          </button>
        </div>
      </div>
      <br />
      <div className="m-0 needs-validation card-body">
        <div className="row align-items-start">{children}</div>
      </div>
    </div>
  )
}

export default Panel
