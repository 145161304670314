import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'

function ProtectedRoutes() {
  const hasToken = !!localStorage.getItem('token')
  if (hasToken) {
    return <Outlet />
  } else {
    return <Navigate replace to="/login" />
  }
}

export default ProtectedRoutes
