import { useEffect, useState, useRef } from 'react'

export default function Tab({
  data,
  activeTabId,
  setActiveTabId,
  setDataPanel,
}) {
  let scrl = useRef(null)
  const [show, SetShow] = useState(false)
  const [scrollX, setscrollX] = useState(0)
  const [scrolEnd, setscrolEnd] = useState(false)

  useEffect(() => {
    if (data.length >= 8) {
      SetShow(true)
    } else {
      SetShow(false)
    }
  })

  const slide = (shift) => {
    scrl.current.scrollLeft += shift
    setscrollX(scrollX + shift)

    if (
      Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
      scrl.current.offsetWidth
    ) {
      setscrolEnd(true)
    } else {
      setscrolEnd(false)
    }
  }

  const scrollCheck = () => {
    setscrollX(scrl.current.scrollLeft)
    if (
      Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
      scrl.current.offsetWidth
    ) {
      setscrolEnd(true)
    } else {
      setscrolEnd(false)
    }
  }
  return (
    <div
      style={{
        display: 'inline-flex',
        width: '100%',
      }}
    >
      {show ? (
        <>
          {scrollX !== 0 && (
            <div
              className="prev"
              onClick={() => slide(-100)}
              style={{
                margin: '5px 20px 10px 5px',
                cursor: 'pointer',
              }}
            >
              <h4>
                <i className="fa fa-angle-left"></i>
              </h4>
            </div>
          )}
        </>
      ) : (
        <div></div>
      )}
      <ul
        className="nav-tabs"
        id="custom-tabs-three-tab"
        ref={scrl}
        onScroll={scrollCheck}
      >
        {data?.map((item) => {
          return (
            <li className="nav-item " key={item.id} id="slid">
              <a
                className={`${
                  item.id === activeTabId && 'active'
                } nav-link text-info`}
                id={item.id}
                data-toggle="pill"
                href="#custom-tabs-three-home"
                role="tab"
                aria-controls="custom-tabs-three-home"
                aria-selected="true"
                onClick={() => {
                  setDataPanel([])
                  setActiveTabId(item.id)
                }}
              >
                {item.label}
              </a>
            </li>
          )
        })}
      </ul>

      {show ? (
        <>
          {!scrolEnd && (
            <div
              className="next"
              onClick={() => slide(+100)}
              style={{
                margin: '5px 5px 5px 20px',
                cursor: 'pointer',
              }}
            >
              <h4>
                <i className="fa fa-angle-right"></i>
              </h4>
            </div>
          )}
        </>
      ) : (
        <div></div>
      )}
    </div>
  )
}
