import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { updateStatus } from '../../Services/AutoLayoutService'
import { handleSaveData } from '../../Utils/ButtonActionUtils'
import Load from '../../Pages/FullLoad'

function ButtonAction({ actionItem, handleSubmit, ...props }) {
  const navigate = useNavigate()
  // loading
  const [loader, showLoader, hideLoader] = Load()
  // redux
  const user = useSelector((state) => state.user)
  const menu = useSelector((state) => state.menu)

  const handleButtonClick = (data) => {
    showLoader()
    const payload = { param: [] }
    // handle redirect
    if (actionItem.isRedirect == '1') {
      navigate(actionItem?.url?.path, { state: payload })
    }

    //handele button save
    let clickedButton = actionItem.label
    if (clickedButton === 'SAVE') {
      const endpoint = '/savedata'
      handleSaveData(endpoint, data, actionItem, showLoader, hideLoader)
    }

    //handel button submit
    if (actionItem.flag === '1') {
      const payload = {
        userId: user.id,
        tc: menu.activeTrackId,
        status: actionItem.flag,
        moduleId: user.activeModule.id,
        param: [],
        field: [],
      }
      actionItem.url.param.forEach((paramId) => {
        const paramValue = document.getElementById(paramId).value
        payload.param.push({ id: paramId, value: paramValue })
      })
      for (const [key, value] of Object.entries(data)) {
        payload.field.push({ fieldId: key, fieldValue: value })
      }
      // console.log(payload)
      // alert('tinggal kirim payload. NOTE : ada code loading juga nanti disini')
      updateStatus(payload).then((res) => {
        if (res.data.status != '1') {
          hideLoader()
          return window.Swal.fire('', res.data.message, 'error')
        }
        window.Swal.fire('Berhasil', res.data.message, 'success')
        window.$('.modal').modal('hide')
        hideLoader()
        navigate('/')
      })
    }
  }

  return (
    <>
      <button
        className={actionItem.className + ' mx-2'}
        onClick={
          handleSubmit ? handleSubmit(handleButtonClick) : handleButtonClick
        }
        {...props}
      >
        <i className={actionItem.icon}></i>
        {actionItem.label}
      </button>
      {loader}
    </>
  )
}

export default ButtonAction
