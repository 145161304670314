import { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { updateStatus, uploadFile } from '../../Services/AutoLayoutService'
import { handleGetGridData, handleGetListData } from '../../Utils/TableUtils'
import { handleSaveData } from '../../Utils/ButtonActionUtils'
import Panel from '../AutoLayout/Panel'
import FieldType from '../AutoLayout/FieldType'
import { useNavigate } from 'react-router-dom'
import Load from '../../Pages/FullLoad'
import { SkeletonModal } from '../AutoLayout/Skeleton'

function ModalWithButton({
  topAction,
  fieldList,
  actionList,
  dataTarget,
  gridItem,
  setFilterData,
  setDataQuery,
  modalcancel,
  modalfilter,
}) {
  // loading
  const [loader, showLoader, hideLoader] = Load()

  const navigate = useNavigate()
  const dispatch = useDispatch()
  // prettier-ignore
  const { register, control, handleSubmit, formState: { errors }, setValue, resetField, getValues, watch } = useForm({ mode: 'onChange' })
  // state
  const [loading, setLoading] = useState(false)
  // redux state
  const user = useSelector((state) => state.user)
  const menu = useSelector((state) => state.menu)

  const refreshGridData = () => {
    // refresh grid data
    const payload = {
      grid: gridItem.id,
      param: [],
      pagination: {
        pageIndex: 1,
        perPage: 10,
      },
    }
    gridItem.reference.parent.forEach((parentId) => {
      const parentValue = document.getElementById(parentId).value
      payload.param.push({ id: parentId, value: parentValue })
    })
    handleGetGridData(payload, setDataQuery)
  }

  const onSubmit = (data) => {
    showLoader()
    actionList?.forEach(async (actionItem) => {
      if (actionItem.flag === 'search') {
        setFilterData(data)
        const payload = {
          userId: user.id,
          menuId: menu.activeMenuId,
          moduleId: user.activeModule.id,
          roleId: user.activeRole.id,
          filtering: [],
          pagination: {
            pageIndex: 1, // hardcode
            perPage: 10,
          },
        }
        for (const [id, value] of Object.entries(data)) {
          payload.filtering.push({ id, value })
        }
        // dispatch dipake nanti ketika filter pagination sudah fix
        // dispatch(setFilteringList(payload.filtering))
        hideLoader()
        handleGetListData(payload, setDataQuery)
        window.$('.modal').modal('hide')
      }
      if (actionItem.flag === 'upload') {
        const formData = new FormData()
        // param
        const param = []
        actionItem.url.param.forEach((paramId) => {
          const paramValue = window.$('#' + paramId).val()
          param.push({ id: paramId, value: paramValue })
        })
        formData.append('param', JSON.stringify(param))
        // fields
        const fields = []
        for (const [id, value] of Object.entries(data)) {
          const fieldEl = window.$('#' + id).attr('type')
          if (fieldEl === 'file') {
            formData.append('file', data[id][0])
          } else {
            fields.push({ id, value })
          }
        }
        formData.append('fields', JSON.stringify(fields))
        // user id
        formData.append('userid', user.id)
        formData.append('tc', menu.activeTrackId)
        formData.append('uploadtype', 'surveyDocument')
        formData.append('lat', 'hardcode') // hardcode
        formData.append('lon', 'hardcode')
        formData.append('addr', 'hardcode')
        await uploadFile(formData).then((res) => {
          if (res.data.status != '1') {
            hideLoader()
            return window.Swal.fire('Error', res.data.message, 'error')
          }
          window.$('.modal').modal('hide')
          window.Swal.fire('Berhasil', res.data.message, 'success')
        })
        refreshGridData()
        hideLoader()
      }
      if (actionItem.flag === 'save') {
        const endpoint = '/savedatamodal'
        handleSaveData(endpoint, data, actionItem, setLoading, hideLoader)
        window.$('.modal').modal('hide')
        refreshGridData()
      }
      if (actionItem.flag === '2' || actionItem.flag === '3') {
        const payload = {
          userId: user.id,
          tc: menu.activeTrackId,
          status: actionItem.flag,
          moduleId: user.activeModule.id,
          param: [],
          field: [],
        }
        actionItem.url.param.forEach((paramId) => {
          const paramValue = document.getElementById(paramId).value
          payload.param.push({ id: paramId, value: paramValue })
        })
        for (const [key, value] of Object.entries(data)) {
          payload.field.push({ fieldId: key, fieldValue: value })
        }
        updateStatus(payload).then((res) => {
          if (res.data.status != '1') {
            return window.Swal.fire('', res.data.message, 'error')
          }
          hideLoader()
          window.Swal.fire('Berhasil', res.data.message, 'success')
          window.$('.modal').modal('hide')
          navigate('/')
        })
      }
    })
  }

  return (
    <>
      <div className="modal fade" id={dataTarget} aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{topAction.label}</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="modal-body ml-2 mr-2">
                {!fieldList?.message &&
                !modalcancel.message &&
                !modalfilter.dataTarget ? (
                  <SkeletonModal />
                ) : (
                  <div className="row">
                    {/* non panel */}
                    {fieldList?.length > 0 &&
                      fieldList?.map((fieldItem) => (
                        <FieldType
                          key={fieldItem.id}
                          fieldItem={fieldItem}
                          fieldList={fieldList.data}
                          register={register}
                          getValues={getValues}
                          setValue={setValue}
                          resetField={resetField}
                          errors={errors}
                          control={control}
                          watch={watch}
                        />
                      ))}
                    {/* if has panel */}
                    {fieldList?.panel?.length > 0 &&
                      fieldList?.panel?.map((panelItem, index, arr) => (
                        <Panel
                          key={index}
                          isLastIndex={index === arr.length - 1 || false}
                          id={panelItem.panelId}
                          name={panelItem.panelName}
                        >
                          <>
                            {panelItem.listField.map((fieldItem) => (
                              <FieldType
                                key={fieldItem.id}
                                fieldItem={fieldItem}
                                fieldList={panelItem.listField}
                                register={register}
                                setValue={setValue}
                                getValues={getValues}
                                resetField={resetField}
                                errors={errors}
                                control={control}
                                watch={watch}
                              />
                            ))}
                          </>
                        </Panel>
                      ))}
                    {/* view document */}
                    {fieldList?.data?.ext === '.pdf' ? (
                      <div
                        style={{
                          textAlign: 'center',
                          margin: '0 auto',
                        }}
                      >
                        <i
                          className="fas fa-file-pdf"
                          style={{
                            fontSize: 100,
                            margin: '10px 5px',
                            color: '#b30b00',
                          }}
                        ></i>
                        <h5>{fieldList.data.docDesc}</h5>
                      </div>
                    ) : fieldList?.data?.ext === '.doc' ? (
                      <div
                        style={{
                          textAlign: 'center',
                          margin: '0 auto',
                        }}
                      >
                        <i
                          className="fas fa-file-word"
                          style={{
                            fontSize: 100,
                            margin: '10px 5px',
                            color: '#2b5796',
                          }}
                        ></i>
                        <h5>{fieldList.data.docDesc}</h5>
                      </div>
                    ) : fieldList?.data?.ext === '.docx' ? (
                      <div
                        style={{
                          textAlign: 'center',
                          margin: '0 auto',
                        }}
                      >
                        <i
                          className="fas fa-file-word"
                          style={{
                            fontSize: 100,
                            margin: '10px 5px',
                            color: '#2b5796',
                          }}
                        ></i>
                        <h5>{fieldList.data.docDesc}</h5>
                      </div>
                    ) : fieldList?.data?.ext === '.xls' ? (
                      <div
                        style={{
                          textAlign: 'center',
                          margin: '0 auto',
                        }}
                      >
                        <i
                          className="fas fa-file-excel"
                          style={{
                            fontSize: 100,
                            margin: '10px 5px',
                            color: '#01723a',
                          }}
                        ></i>
                        <h5>{fieldList.data.docDesc}</h5>
                      </div>
                    ) : fieldList?.data?.ext === '.xlsx' ? (
                      <div
                        style={{
                          textAlign: 'center',
                          margin: '0 auto',
                        }}
                      >
                        <i
                          className="fas fa-file-excel"
                          style={{
                            fontSize: 100,
                            margin: '10px 5px',
                            color: '#01723a',
                          }}
                        ></i>
                        <h5>{fieldList.data.docDesc}</h5>
                      </div>
                    ) : fieldList?.data?.ext === '.csv' ? (
                      <div
                        style={{
                          textAlign: 'center',
                          margin: '0 auto',
                        }}
                      >
                        <i
                          className="fas fa-file-csv"
                          style={{
                            fontSize: 100,
                            margin: '10px 5px',
                            color: '#01723a',
                          }}
                        ></i>
                        <h5>{fieldList.data.docDesc}</h5>
                      </div>
                    ) : fieldList?.data?.ext === '.txt' ? (
                      <div
                        style={{
                          textAlign: 'center',
                          margin: '0 auto',
                        }}
                      >
                        <i
                          className="fas fa-file-alt"
                          style={{
                            fontSize: 100,
                            margin: '10px 5px',
                          }}
                        ></i>
                        <h5>{fieldList.data.docDesc}</h5>
                      </div>
                    ) : fieldList?.data?.ext === '.png' ? (
                      <>
                        {fieldList?.data?.result && (
                          <div
                            style={{
                              textAlign: 'center',
                              margin: '0 auto',
                            }}
                          >
                            <img
                              src={`data:image/png;base64,${fieldList.data.result}`}
                              alt=""
                              className="img-fluid"
                              style={{
                                width: '100%',
                                marginBottom: 10,
                              }}
                            />
                            <h5>{fieldList.data.docDesc}</h5>
                          </div>
                        )}
                      </>
                    ) : fieldList?.data?.ext === '.jpg' ? (
                      <>
                        {fieldList?.data?.result && (
                          <div
                            style={{
                              textAlign: 'center',
                              margin: '0 auto',
                            }}
                          >
                            <img
                              src={`data:image/png;base64,${fieldList.data.result}`}
                              alt=""
                              className="img-fluid"
                              style={{
                                width: '100%',
                                marginBottom: 10,
                              }}
                            />
                            <h5>{fieldList.data.docDesc}</h5>
                          </div>
                        )}
                      </>
                    ) : fieldList?.data?.ext === '.jpeg' ? (
                      <>
                        {fieldList?.data?.result && (
                          <div
                            style={{
                              textAlign: 'center',
                              margin: '0 auto',
                            }}
                          >
                            <img
                              src={`data:image/png;base64,${fieldList.data.result}`}
                              alt=""
                              className="img-fluid"
                              style={{
                                width: '100%',
                                marginBottom: 10,
                              }}
                            />
                            <h5>{fieldList.data.docDesc}</h5>
                          </div>
                        )}
                      </>
                    ) : (
                      <>
                        {fieldList?.data?.result && (
                          <div
                            style={{
                              textAlign: 'center',
                              margin: '0 auto',
                            }}
                          >
                            <i
                              className="fas fa-file"
                              style={{
                                fontSize: 100,
                                marginBottom: 10,
                              }}
                            ></i>
                            <h5>{fieldList.data.docDesc}</h5>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                )}
              </div>
              <div className="modal-footer">
                {actionList?.map((actionItem, index) => (
                  <button
                    key={index}
                    type="button"
                    onClick={handleSubmit(onSubmit)}
                    className={actionItem.className}
                    disabled={loading}
                  >
                    {actionItem.label}
                  </button>
                ))}
                {fieldList?.data?.ext === '.pdf' ? (
                  <div>
                    <a
                      className="btn btn-sm btn-info"
                      download={fieldList.data.docDesc}
                      href={`data:application/pdf;base64,${fieldList.data.result}`}
                    >
                      <i className="fas fa-arrow-down"></i> Download
                    </a>
                  </div>
                ) : fieldList?.data?.ext === '.doc' ? (
                  <div>
                    <a
                      className="btn btn-sm btn-info"
                      download={fieldList.data.docDesc}
                      href={`data:application/msword;base64,${fieldList.data.result}`}
                    >
                      <i className="fas fa-arrow-down"></i> Download
                    </a>
                  </div>
                ) : fieldList?.data?.ext === '.docx' ? (
                  <div>
                    <a
                      className="btn btn-sm btn-info"
                      download={fieldList.data.docDesc}
                      href={`data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,${fieldList.data.result}`}
                    >
                      <i className="fas fa-arrow-down"></i> Download
                    </a>
                  </div>
                ) : fieldList?.data?.ext === '.xls' ? (
                  <div>
                    <a
                      className="btn btn-sm btn-info"
                      download={fieldList.data.docDesc}
                      href={`data:application/vnd.ms-excel;base64,${fieldList.data.result}`}
                    >
                      <i className="fas fa-arrow-down"></i> Download
                    </a>
                  </div>
                ) : fieldList?.data?.ext === '.xlsx' ? (
                  <div>
                    <a
                      className="btn btn-sm btn-info"
                      download={fieldList.data.docDesc}
                      href={`data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8;base64,${fieldList.data.result}`}
                    >
                      <i className="fas fa-arrow-down"></i> Download
                    </a>
                  </div>
                ) : fieldList?.data?.ext === '.csv' ? (
                  <div>
                    <a
                      className="btn btn-sm btn-info"
                      download={fieldList.data.docDesc}
                      href={`data:text/csv;base64,${fieldList.data.result}`}
                    >
                      <i className="fas fa-arrow-down"></i> Download
                    </a>
                  </div>
                ) : fieldList?.data?.ext === '.txt' ? (
                  <div>
                    <a
                      className="btn btn-sm btn-info"
                      download={fieldList.data.docDesc}
                      href={`data:text/plain;base64,${fieldList.data.result}`}
                    >
                      <i className="fas fa-arrow-down"></i> Download
                    </a>
                  </div>
                ) : (
                  <>
                    {fieldList?.data?.result && (
                      <div>
                        <a
                          className="btn btn-sm btn-info"
                          download={fieldList.data.docDesc}
                          href={`data:image/png;base64,${fieldList.data.result}`}
                        >
                          <i className="fas fa-arrow-down"></i> Download
                        </a>
                      </div>
                    )}
                  </>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
      {loader}
    </>
  )
}

export default ModalWithButton
