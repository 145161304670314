import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import ButtonType from '../Components/AutoLayout/ButtonType'
import FieldType from '../Components/AutoLayout/FieldType'
// components
import Panel from '../Components/AutoLayout/Panel'
import Skeleton from '../Components/AutoLayout/Skeleton'
import Tab from '../Components/AutoLayout/Tab'
import ButtonAction from '../Components/Button/ButtonAction'
// service
import { getField, getTab, saveForm } from '../Services/AutoLayoutService'

function AutoLayout() {
  const navigate = useNavigate()
  const { state } = useLocation()
  // prettier-ignore
  const { register, handleSubmit, formState: { errors }, setValue, getValues, resetField, watch, clearErrors, control } = useForm({mode: 'onChange'})
  // state
  const [data, setData] = useState()
  const [tab, setTab] = useState()
  const [activeTabId, setActiveTabId] = useState('')
  // redux state
  const user = useSelector((state) => state.user)
  const menu = useSelector((state) => state.menu)
  const hiddenPanel = useSelector((state) => state.hiddenElement.hiddenPanel)

  useEffect(() => {
    // handle form reload by menuId condition
    if (menu.activeMenuId === '') return navigate('/dashboard')
    // handle tab
    getTab(menu.activeMenuId, user.activeModule.id).then((res) => {
      if (res.data.status != '1') {
        return window.Swal.fire('Kesalahan', res.data.response, 'error')
      }
      setTab(res.data.data)
      setActiveTabId(res.data.data[0].id) // by default, set active tab = first tab index
    })
  }, [])

  // handle get field
  useEffect(() => {
    if (activeTabId !== '') {
      const payload = {
        tabId: activeTabId,
        tc: menu.activeTrackId,
        userId: user.id,
        param: state.param,
      }
      // get field by payload
      getField(payload).then((res) => {
        if (res.data.status != '1') {
          return window.Swal.fire('Kesalahan', res.data.message, 'error')
        }
        setData(res.data)
      })
    }
  }, [activeTabId])

  return (
    <>
      {!data || !tab ? (
        <Skeleton />
      ) : (
        <form>
          <div className="info-box bg-info">
            <span className="info-box-icon">
              <i className="far fa-bookmark"></i>
            </span>
            <div className="info-box-content">
              <span className="info-box-number text-md">
                {/* first list field value */}
                {data.panel && data?.panel[0]?.listField[0]?.value}
              </span>
            </div>
          </div>
          <div className="card card-info card-outline card-outline-tabs">
            <div className="card-header pl-2 pr-2 border-bottom-0">
              <div>
                <Tab
                  data={tab}
                  activeTabId={activeTabId}
                  setActiveTabId={setActiveTabId}
                  setDataPanel={setData}
                />
              </div>
            </div>

            <div className="card-body">
              {data?.panel?.map((panelItem, index, arr) => (
                <>
                  {!hiddenPanel.includes(panelItem.panelId) && (
                    <Panel
                      key={index}
                      isLastIndex={index === arr.length - 1 || false}
                      id={panelItem.panelId}
                      name={panelItem.panelName}
                    >
                      <>
                        {panelItem.listField.map((fieldItem) => (
                          <FieldType
                            key={fieldItem.id}
                            panel={data.panel}
                            fieldItem={fieldItem}
                            fieldList={panelItem.listField}
                            register={register}
                            setValue={setValue}
                            getValues={getValues}
                            clearErrors={clearErrors}
                            resetField={resetField}
                            errors={errors}
                            activeTabId={activeTabId}
                            watch={watch}
                            control={control}
                          />
                        ))}
                      </>
                    </Panel>
                  )}
                </>
              ))}
            </div>
            {data?.action?.length > 0 && (
              <div className="card-footer border-0 py-2">
                <div className="d-flex justify-content-end ">
                  {data?.action?.map((actionItem) => (
                    <ButtonType
                      key={actionItem.actionId}
                      buttonItem={actionItem}
                      handleSubmit={handleSubmit}
                    />
                  ))}
                </div>
              </div>
            )}
          </div>
        </form>
      )}
    </>
  )
}

export default AutoLayout
