import { useEffect, useState } from 'react'
import { getReference } from '../../../Services/AutoLayoutService'
import { useWatch } from 'react-hook-form'
import {
  dateDisplay,
  dateNowDisplay,
  dateNowValue,
  dateValue,
  getAge,
} from '../../../Utils/DatetimeUtils'

function InputDate({
  label,
  id,
  register,
  child,
  setValue,
  view,
  defaultValue,
  watch,
  isMandatory,
  hide,
  ...props
}) {
  const [displayValue, setDisplayValue] = useState('')

  window.$(function () {
    window.$(`#date_${id}`).daterangepicker({
      singleDatePicker: true,
      autoUpdateInput: false,
      showDropdowns: true,
      locale: {
        format: process.env.REACT_APP_DATE_FORMATDISP,
      },
      minYear: 1900,
    })
    // on apply
    window.$(`#date_${id}`).on('apply.daterangepicker', function (ev, picker) {
      const newDisplayValue = dateDisplay(
        picker.startDate,
        process.env.REACT_APP_DATE_FORMATDISP
      )
      const newInputValue = dateValue(
        picker.startDate,
        process.env.REACT_APP_DATE_FORMATDISP
      )
      setDisplayValue(newDisplayValue)
      setValue(id, newInputValue)
      window.$('.daterangepicker').hide()
    })
    // on cancel
    // window
    //   .$(`#date_${id}`)
    //   .on('cancel.daterangepicker', function (ev, picker) {
    //     window.$(this).val('')
    //   })
  })

  useEffect(() => {
    if (child.length !== 0) {
      const age = getAge(displayValue)
      const childId = child[0]
      setValue(childId, age)
    }
  }, [displayValue])

  const inputWatch = watch(id)
  useEffect(() => {
    if (inputWatch && inputWatch !== '') {
      setDisplayValue(
        dateDisplay(inputWatch, process.env.REACT_APP_DATE_FORMATVAL)
      )
    }
  }, [setDisplayValue, inputWatch])

  useEffect(() => {
    if (displayValue && displayValue !== '') {
      setValue(
        id,
        dateValue(displayValue, process.env.REACT_APP_DATE_FORMATDISP)
      )
    }
  }, [displayValue])

  useEffect(() => {
    if (defaultValue && defaultValue !== '') {
      setValue(
        id,
        dateValue(defaultValue, process.env.REACT_APP_DATE_FORMATVAL)
      )
    }
  }, [])

  return (
    <>
      <label className={`${hide ? 'hidden' : ''}`}>
        {label}
        {isMandatory && (
          <span className="text-danger font-weight-bold"> *</span>
        )}
      </label>
      <div className="input-group">
        <div className="input-group-prepend">
          <span className="input-group-text">
            <i className="far fa-calendar-alt"></i>
          </span>
        </div>
        <input
          type="text"
          className="form-control datetimepicker-input"
          id={`date_${id}`}
          autoComplete="off"
          {...props}
          value={displayValue}
          onChange={(e) => e.preventDefault()}
          onKeyDown={(e) => e.preventDefault()}
        />
        <input
          type="hidden"
          {...props}
          {...register}
          id={id}
          onChange={(e) => e.preventDefault()}
          onKeyDown={(e) => e.preventDefault()}
        />
      </div>
    </>
  )
}

export default InputDate
