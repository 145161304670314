export const panelCondition = {
  panel: [
    {
      panelName: 'PENGAJUAN AWAL',
      panelId: 'PA000002',
      listField: [
        {
          id: 'PA000001_003',
          label: 'No Aplikasi',
          type: 'textbox',
          maxLength: '100',
          isMandatory: '1',
          rule: '',
          isReadOnly: '0',
          width: '6',
          isMultiple: '',
          path: '',
          hide: '',
          value: '001541002023',
          reference: {
            child: [],
          },
        },
        {
          id: 'PA000001_005',
          label: 'Status Perkawinan',
          type: 'dropdown',
          maxLength: '100',
          isMandatory: '0',
          rule: '',
          isReadOnly: '0',
          width: '6',
          isMultiple: '',
          path: '',
          hide: '',
          condition: [
            {
              value: ['1'],
              setting: [
                {
                  id: 'PA000003',
                  property: {
                    visible: '1',
                    isMandatory: '1',
                  },
                },
              ],
            },
            {
              value: ['2', '3', '4'],
              setting: [
                {
                  id: 'PA000003',
                  property: {
                    visible: '0',
                    isMandatory: '0',
                  },
                },
              ],
            },
          ],
          valueList: [],
          reference: {},
        },
      ],
      table: {},
    },
    {
      panelName: 'DATA PASANGAN',
      panelId: 'PA000003',
      listField: [
        {
          id: 'PA000002_001',
          label: 'No Aplikasi',
          type: 'textbox',
          maxLength: '100',
          isMandatory: '1',
          rule: '',
          isReadOnly: '1',
          width: '6',
          isMultiple: '',
          path: '',
          hide: '0',
          value: '001541002023',
          reference: {
            child: [],
          },
        },
        {
          id: 'PA000002_001_1',
          label: 'Nama Sesuai KTP',
          type: 'textbox',
          maxLength: '100',
          isMandatory: '1',
          rule: '',
          isReadOnly: '0',
          width: '6',
          isMultiple: '',
          path: '',
          hide: '',
          value: 'sfsfasfa',
          reference: {
            child: [],
          },
        },
        {
          id: 'PA000002_002',
          label: 'Tempat Lahir',
          type: 'textbox',
          maxLength: '100',
          isMandatory: '1',
          rule: '',
          isReadOnly: '0',
          width: '6',
          isMultiple: '',
          path: '',
          hide: '',
          value: 'safsafsaf',
          reference: {
            child: [],
          },
        },
        {
          id: 'PA000002_003',
          label: 'Tanggal Lahir',
          type: 'date',
          maxLength: '100',
          isMandatory: '1',
          rule: '',
          isReadOnly: '0',
          width: '6',
          isMultiple: '',
          path: '',
          hide: '',
          value: '1900-01-01',
          view: 'date',
          reference: {
            child: [],
          },
        },
        {
          id: 'PA000002_004',
          label: 'No KTP',
          type: 'textbox',
          maxLength: '100',
          isMandatory: '1',
          rule: '',
          isReadOnly: '0',
          width: '6',
          isMultiple: '',
          path: '',
          hide: '',
          value: '24242414122',
          reference: {
            child: [],
          },
        },
        {
          id: 'PA000002_005',
          label: 'Agama',
          type: 'dropdown',
          maxLength: '100',
          isMandatory: '0',
          rule: '',
          isReadOnly: '0',
          width: '6',
          isMultiple: '',
          path: '',
          hide: '',
          condition: [],
          valueList: [],
          reference: {},
        },
      ],
      table: {},
    },
  ],
}
