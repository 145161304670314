import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getReference } from '../../../Services/AutoLayoutService'
import { resetDropdown, setDropdown } from '../../../Store/Input/DropdownSlice'

function DropdownSelect({
  label,
  id,
  panel = [],
  listField,
  fieldItem,
  isLocation,
  isReadOnly,
  isMandatory,
  parent,
  child,
  register,
  resetField,
  defaultValue,
  condition,
  getValues,
  setValue,
}) {
  const dropdownDataState = useSelector((state) => state.dropdown)
  const dispatch = useDispatch()
  const [cascadeData, setCascadeData] = useState([])

  useEffect(() => {
    if (dropdownDataState.data.length > 0) {
      for (let i = 0; i < dropdownDataState.data.length; i++) {
        if (dropdownDataState.data[i].id === id) {
          setCascadeData(dropdownDataState.data[i].valueList)
          setValue(id, dropdownDataState.data[i].valueList[0].value)
          if (dropdownDataState.data[i].parentId) {
            const parentvalue = getValues(dropdownDataState.data[i].parentId)
            setValue(id, parentvalue)
          }
        }
      }
      dispatch(resetDropdown())
    }
  }, [dropdownDataState])

  useEffect(() => {
    // handle default value
    if (defaultValue && defaultValue.length) {
      setCascadeData(defaultValue)
      setValue(id, defaultValue[0].value)
    }

    const dropdownEl = window.$('#' + id)
    // on dropdown show
    dropdownEl.on('show.bs.select', () => {
      // payload for request cascade
      const payload = { referenceName: id, param: [] }

      // check if dropdown has parent
      if (parent && parent.length) {
        parent.forEach((parentId) => {
          const parentValue = getValues(parentId)
          // push selected parent value to payload param
          payload.param.push({ id: parentId, value: parentValue })
        })
      }
      // if dropdown doesnt has parent (dropdown parent)
      else {
        // push empty id & value to payload
        payload.param.push({ id: '', value: '' })
      }

      // if location = top action (filter modal)
      if (isLocation == 'topAction') {
        Object.assign(payload, { isLocation: 'topAction' })
      }

      // get reference
      getReference(payload).then((res) =>
        // set dropdown option
        setCascadeData(res.data.data)
      )
    })

    // if has parent
    if (parent.length > 0) {
      parent.forEach((parentId) => {
        const parentEl = document.getElementById(parentId)
        // if dropdown parent is changed
        parentEl.addEventListener('change', () => {
          // console.log(`parent ${parentId} change`)
          // set empty current cascade data
          setCascadeData([])
          // reset current field value
          resetField(id)
          const currentDropdown = document.getElementById(id)
          // currentDropdown.value = '' // set selected value to default label
          currentDropdown.dispatchEvent(new Event('change'))
          // reset child field
          if (child.length) {
            child.forEach((childId) => {
              resetField(childId)
            })
          }
        })
      })
    }

    // if has child
    // get child value by child parent
    if (child.length > 0) {
      child.forEach((childId) => {
        dropdownEl.on('changed.bs.select', (e) => {
          setValue(id, e.target.value) // set current value
          let emptyField = false
          const payload = { referenceName: childId, param: [] }
          // find child field
          const childField = listField.filter(
            (fieldItem) => fieldItem.id === childId
          )
          // get child parent
          childField[0].reference.parent.forEach((parentId) => {
            const parentValue = getValues(parentId)
            payload.param.push({ id: parentId, value: parentValue })
            if (!parentValue || parentValue === '') {
              emptyField = true
              // console.log(
              //   `(get child ${childId})`,
              //   'parent',
              //   parentId,
              //   'is empty,',
              //   'from',
              //   label
              // )
            }
          })
          // console.log(payload)
          // if doenst has empty field value
          if (!emptyField) {
            console.warn('get child', childId, `from ${label}`)
            // get child value by payload
            getReference(payload).then((res) => {
              if (res.data.status != '1') {
                return window.Swal.fire('', res.data.message, 'error')
              }
              // set response value to child
              if (res.data.data.length) {
                setValue(childId, res.data.data[0].value)
                const childEl = document.getElementById(childId)
                childEl.dispatchEvent(new Event('change'))
              }
            })
          }
        })
      })
    }

    // if has condition
    if (condition?.length > 0) {
      const handleConditionValue = (currentValue) => {
        condition.forEach((conditionItem) => {
          if (conditionItem.value.includes(currentValue)) {
            conditionItem.setting.forEach((setting) => {
              const targetEl = document.getElementById(setting.id)
              const offsetParent = targetEl.parentElement.parentElement
              if (setting.property.visible === '1') {
                // SHOW CONDITION
                if (['SELECT', 'INPUT'].includes(targetEl.tagName)) {
                  offsetParent.style.display = 'block'
                  setValue(setting.id, '')
                } else {
                  targetEl.style.display = 'block'
                  // prettier-ignore
                  const filteredPanel = panel.find((panelItem) => panelItem.panelId === setting.id)
                  // set every field value inside panel to '' (empty string)
                  filteredPanel.listField.forEach((fieldItem, index) => {
                    // prettier-ignore
                    const handleValue = () => {
                      if (fieldItem.value !== '') return fieldItem.value
                      if (fieldItem.valueList?.length) return fieldItem.valueList[0]?.value
                      // default
                      return ''
                    }
                    // except first index (ap_regno)
                    if (index !== 0) setValue(fieldItem.id, handleValue())
                  })
                }
              } else {
                // HIDE CONDITION
                if (['SELECT', 'INPUT'].includes(targetEl.tagName)) {
                  offsetParent.style.display = 'none'
                  setValue(setting.id, '-')
                } else {
                  targetEl.style.display = 'none'
                  // prettier-ignore
                  const filteredPanel = panel.find((panelItem) => panelItem.panelId === setting.id)
                  // set every field value inside panel to '-'
                  filteredPanel.listField.forEach((fieldItem, index) => {
                    // except first index (ap_regno)
                    if (index !== 0) setValue(fieldItem.id, '-')
                  })
                }
              }
            })
          }
        })
      }
      // on first load
      const currentValue = defaultValue.length ? defaultValue[0].value : ''
      handleConditionValue(currentValue)
      // on dropdown changed
      dropdownEl.on('changed.bs.select', () => {
        const currentValue = window.$('#' + id).val()
        handleConditionValue(currentValue)
      })
    }

    dropdownEl.on('changed.bs.select', (e) => {
      setValue(id, e.target.value)
    })
  }, [])

  // if cascadeDate is changed => refresh selectpicker
  useEffect(() => {
    window.$('#' + id).selectpicker('refresh')
  }, [cascadeData])

  return (
    <>
      <label onClick={() => console.log(fieldItem)}>
        {label}
        {isMandatory && (
          <span className="text-danger font-weight-bold"> *</span>
        )}
      </label>
      <select
        id={id}
        className="selectpicker w-100"
        data-live-search="true"
        data-style=""
        data-style-base="form-control"
        defaultValue={defaultValue?.length ? defaultValue[0].value : ''}
        {...register}
        disabled={isReadOnly}
      >
        <option value="" disabled>
          Pilih {label}
        </option>
        {cascadeData?.length > 0 &&
          cascadeData?.map((data) => (
            <option key={data.value} value={data.value}>
              {data.label}
            </option>
          ))}
      </select>
      {/* <small>
        id: {id}. parent: {JSON.stringify(parent)}. child:{' '}
        {JSON.stringify(child)}
      </small> */}
    </>
  )
}

export default DropdownSelect
