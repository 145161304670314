import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { getDataActionWithButton } from '../../../Services/AutoLayoutService'
import { resetDropdown, setDropdown } from '../../../Store/Input/DropdownSlice'

const InputTextboxWithButton = ({
  button,
  child,
  clearErrors,
  defaultValue,
  flag,
  getValues,
  hide,
  id,
  isMandatory,
  label,
  parent,
  path,
  register,
  resetField,
  setValue,
  width,
  ...props
}) => {
  const dispatch = useDispatch()
  const [textboxwidth, setTextboxWidth] = useState(12)
  const onClick = (e) => {
    e.preventDefault()
    const payload = {
      type: button[0].id,
      param: [
        {
          id,
          value: getValues(id),
        },
      ],
    }
    if (getValues(id) !== '') {
      dispatch(resetDropdown())
      getDataActionWithButton(button[0].path, payload).then((res) => {
        const data = res.data.data
        if (res.data.status === '1' && data.length !== 0) {
          let dataArray = []
          for (let i = 0; i < data.length; i++) {
            if (data[i].value && data[i].value !== '') {
              resetField(data[i].id)
              setValue(data[i].id, data[i].value)
            }
            if (data[i].valueList && data[i].valueList.length > 0) {
              for (let j = 0; j < data[i].valueList.length; j++) {
                if (
                  data[i].valueList[j].value &&
                  data[i].valueList[j].value !== ''
                ) {
                  const dropdown = {
                    id: data[i].id,
                    valueList: data[i].valueList,
                  }
                  dataArray = [...dataArray, dropdown]
                }
              }
              dispatch(setDropdown({ data: dataArray }))
            }
          }
          window.Swal.fire('Berhasil', `Data ditemukan`, 'success')
        } else {
          window.Swal.fire('Peringatan', `Data tidak ditemukan`, 'warning')
        }
      })
    } else {
      window.Swal.fire('Peringatan', `Silahkan isi data ${label}`, 'warning')
    }
  }

  useEffect(() => {
    setTextboxWidth(12 - button[0].width)
  }, [])

  return (
    <>
      <label className={`${hide ? 'hidden' : ''}`}>
        {label}
        {isMandatory && (
          <span className="text-danger font-weight-bold"> *</span>
        )}
      </label>
      <div className={`input-group`}>
        <input
          type="text"
          defaultValue={defaultValue}
          {...register}
          id={id}
          className={`form-control`}
          {...props}
        />
        <span className="input-group-append">
          <button type="button" className="btn btn-info" onClick={onClick}>
            {button[0].label}
          </button>
        </span>
      </div>
    </>
  )
}

export default InputTextboxWithButton
