import { useState } from 'react'
import ButtonType from '../AutoLayout/ButtonType'

function TopAction({ structures, setDataQuery, gridItem }) {
  // state
  const [filterData, setFilterData] = useState()

  const handleFilterPopup = (data) => {
    setFilterData(data)
    window.$('#filterModal').modal('hide')
  }

  const handleFilterDataLabel = () => {
    const searchCriteria = []
    structures?.topAction.forEach((topActionItem) => {
      // note
      if (topActionItem.label === 'Filter') {
        topActionItem.contents.data.forEach((content) => {
          if (filterData[content.id])
            searchCriteria.push(`${content.label} = ${filterData[content.id]}`)
        })
      }
    })
    const res = 'Search Criteria : ' + searchCriteria.join(', ')
    return res
  }

  return (
    <div className="mb-2 row">
      <div className="col">
        {filterData && (
          <div className="callout callout-info shadow-none">
            <p>{handleFilterDataLabel()}</p>
          </div>
        )}
      </div>
      <div className="col-md-3 d-flex align-items-center justify-content-end">
        {structures?.topAction?.map((buttonItem, index) => (
          <ButtonType
            key={index}
            buttonItem={buttonItem}
            gridItem={gridItem}
            setDataQuery={setDataQuery}
            setFilterData={setFilterData}
          />
        ))}
      </div>
    </div>
  )
}

export default TopAction
