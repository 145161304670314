import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  hiddenPanel: [],
  hiddenField: [],
}

export const hiddenElementSlice = createSlice({
  name: 'hiddenElement',
  initialState,
  reducers: {
    setHiddenPanel: (state, action) => {
      state.hiddenPanel = action.payload
    },
    setHiddenField: (state, action) => {
      state.hiddenField = action.payload
    },
  },
})

export const { setHiddenField, setHiddenPanel } = hiddenElementSlice.actions

export const hiddenElementReducer = hiddenElementSlice.reducer
