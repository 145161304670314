import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  id: '',
  data: [],
}

export const dropdownSlice = createSlice({
  name: 'dropdown',
  initialState,
  reducers: {
    setDropdown: (state, action) => {
      state.id = action.payload.id
      state.data = action.payload.data
    },
    resetDropdown: (state) => {
      state.id = ''
      state.data = []
    },
  },
})

export const { setDropdown, resetDropdown } = dropdownSlice.actions

export const dropdownReducer = dropdownSlice.reducer
