import { useEffect, useMemo, useState } from 'react'
// prettier-ignore
import { handleGetGridData, handleGetGridStructure, handleStructureHeader } from '../Utils/TableUtils'
import TableComponent from '../Components/Table/TableComponent'

function TableGrid({ gridItem, activeTabId }) {
  // state
  const [dataQuery, setDataQuery] = useState()
  const [structures, setStructures] = useState({})
  const [columnVisibility, setColumnVisibility] = useState({ ap_regno: false })
  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  })

  // get structure
  useEffect(() => {
    setPagination({ pageIndex: 0, pageSize: 10 })
    handleGetGridStructure(setStructures, gridItem)
  }, [activeTabId])

  // get data
  useEffect(() => {
    const payload = {
      grid: gridItem.id,
      param: [],
      pagination: {
        pageIndex: pageIndex + 1,
        perPage: pageSize,
      },
    }
    gridItem.reference.parent.forEach((parentId) => {
      const parentValue = document.getElementById(parentId).value
      payload.param.push({ id: parentId, value: parentValue })
    })
    handleGetGridData(payload, setDataQuery)
  }, [pageIndex, pageSize, activeTabId])

  return (
    <TableComponent
      dataQuery={dataQuery}
      structures={structures}
      columnVisibility={columnVisibility}
      pageIndex={pageIndex}
      pageSize={pageSize}
      gridItem={gridItem}
      setDataQuery={setDataQuery}
      setColumnVisibility={setColumnVisibility}
      setPagination={setPagination}
    />
  )
}

export default TableGrid
