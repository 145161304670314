import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getCancelModal, getViewDoc } from '../../Services/AutoLayoutService'
import { getFieldByGrid } from '../../Services/StructureService'
import ModalWithButton from '../Modal/ModalWithButton'

function ButtonModal({
  dataTarget,
  label,
  gridItem,
  icon,
  topAction,
  setFilterData,
  setDataQuery,
  info,
  ...props
}) {
  // state
  const [fieldList, setFieldList] = useState([])
  const [actionList, setActionList] = useState([])
  const [modalcancel, setModalCancel] = useState({})
  const [modalfilter, setModalfilter] = useState({})

  // redux state
  const menu = useSelector((state) => state.menu)

  const handleSetFieldListModal = async (topAction) => {
    // if has content (filter modal)
    if (topAction.contents?.data?.length >= 1) {
      // note: nanti contents dibagi 2 jadi data & action
      setFieldList(topAction.contents.data)
      setActionList(topAction.contents.action)
      setModalfilter(topAction)
      return
    }

    // view modal
    if (topAction.label === 'View') {
      const payload = {
        param: [],
      }
      // handle param
      topAction.url.param.forEach((paramId) => {
        const paramFieldValue = window.$('#' + paramId).val()
        if (paramFieldValue) {
          payload.param.push({ id: paramId, value: paramFieldValue })
        } else {
          const paramRowValue = info.row.original[paramId]
          payload.param.push({ id: paramId, value: paramRowValue })
        }
      })
      await getViewDoc(payload).then((res) => {
        if (res.data.status != '1') {
          return window.Swal.fire('Error', res.data.message, 'error')
        }
        setFieldList(res.data)
      })
      return
    }

    // cancel modal
    if (topAction.flag === '2' || topAction.flag === '3') {
      const payload = {
        cancelId: topAction.actionId,
        param: [],
      }
      topAction.url.param.forEach((paramId) => {
        const paramValue = document.getElementById(paramId).value
        payload.param.push({ id: paramId, value: paramValue })
      })
      await getCancelModal(payload).then((res) => {
        setFieldList(res.data.data.listField)
        setActionList(res.data.data.action)
        setModalCancel(res.data)
      })
      return
    }

    const isNew = topAction.isNew == '1' ? '1' : '0'
    const payload = {
      gridId: gridItem.id,
      buttonId: topAction.buttonId,
      isNew: isNew,
      tc: menu.activeTrackId,
      param: [],
    }
    // set param by paramId
    topAction.url.param.forEach((paramId) => {
      const paramFieldValue = window.$('#' + paramId).val()
      if (paramFieldValue) {
        payload.param.push({ id: paramId, value: paramFieldValue })
      } else {
        const paramRowValue = info.row.original[paramId]
        payload.param.push({ id: paramId, value: paramRowValue })
      }
    })
    await getFieldByGrid(payload).then((res) => {
      if (res.data.status != '1') {
        return window.Swal.fire('', res.data.message, 'error')
      }
      setFieldList(res.data)
      setActionList(res.data.action)
    })
  }

  useEffect(() => {
    window.$('#' + dataTarget).on('hidden.bs.modal', function () {
      setFieldList([])
      setActionList([])
    })
  }, [])

  return (
    <>
      <button
        type="button"
        data-toggle="modal"
        data-target={'#' + dataTarget}
        onClick={() => handleSetFieldListModal(topAction)}
        {...props}
      >
        <i className={icon}></i>
        <span>{label}</span>
      </button>
      <ModalWithButton
        fieldList={fieldList}
        dataTarget={dataTarget}
        actionList={actionList}
        setFilterData={setFilterData}
        topAction={topAction}
        gridItem={gridItem}
        setDataQuery={setDataQuery}
        modalcancel={modalcancel}
        modalfilter={modalfilter}
      />
    </>
  )
}

export default ButtonModal
