import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  filtering: [],
}

export const listSlice = createSlice({
  name: 'list',
  initialState,
  reducers: {
    setFilteringList: (state, action) => {
      state.filtering = action.payload
    },
  },
})

export const { setFilteringList } = listSlice.actions

export const listReducer = listSlice.reducer
