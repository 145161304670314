import { useEffect } from 'react'
import bsCustomFileInput from 'bs-custom-file-input'

function InputFile({ fieldItem, register, ...props }) {
  useEffect(() => {
    bsCustomFileInput.init()
  })

  return (
    <div className="form-group">
      <label>{fieldItem.label}</label>
      <div className="custom-file">
        <input
          type="file"
          id={fieldItem.id}
          className="custom-file-input"
          {...register}
          {...props}
        />
        <label className="custom-file-label">Pilih file</label>
      </div>
    </div>
  )
}

export default InputFile
