import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import InputCommon from '../Components/AutoLayout/Input/InputCommon'
import { setMenuSlice } from '../Store/Menu/menuSlice'

function Profile() {
  const dispatch = useDispatch()
  const { register, handleSubmit } = useForm()

  const updateProfile = (data) => {
    console.log(data)
  }

  useEffect(() => {
    dispatch(setMenuSlice({ menuId: 'profileMenuId', menuDesc: 'Profile' }))
  }, [])

  return (
    <div className="row">
      <div className="col-md-7">
        <div className="card">
          <div className="card-header">
            <div className="card-title">
              <i className="fal fa-user mr-2"></i>
              Edit Profile
            </div>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(updateProfile)}>
              <div className="form-group">
                <InputCommon
                  label="Name"
                  isMandatory={true}
                  type="text"
                  register={register('name', {
                    required: 'Name is required',
                    minLength: {
                      value: 4,
                      message: 'Name minimal 4',
                    },
                  })}
                />
              </div>
              <button type="submit" className="btn btn-info btn-sm">
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
      <div className="col-md-5">
        <div className="card">
          <div className="card-header">
            <div className="card-title">
              <i className="fal fa-lock mr-2"></i>
              Change Password
            </div>
          </div>
          <div className="card-body">
            <div className="form-group">
              <InputCommon
                type="text"
                label="Old Password"
                isMandatory={true}
                register={register('password', {
                  required: 'Password harus diisi',
                  minLength: {
                    value: 6,
                    message: 'Password minimal 6 karakter',
                  },
                })}
              />
            </div>
            <div className="form-group">
              <InputCommon
                type="text"
                label="New Password"
                isMandatory={true}
                register={register('password', {
                  required: 'Password harus diisi',
                  minLength: {
                    value: 6,
                    message: 'Password minimal 6 karakter',
                  },
                })}
              />
            </div>
            <div className="form-group">
              <InputCommon
                type="text"
                label="Confirm New Password"
                isMandatory={true}
                register={register('password', {
                  required: 'Password harus diisi',
                  minLength: {
                    value: 6,
                    message: 'Password minimal 6 karakter',
                  },
                })}
              />
            </div>
            <button type="submit" className="btn btn-info btn-sm">
              Change Password
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Profile
