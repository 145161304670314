import React, { useEffect, useState } from 'react'
import { useWatch } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { getDataActionWithButton } from '../../../Services/AutoLayoutService'
import { resetDropdown, setDropdown } from '../../../Store/Input/DropdownSlice'

const CheckboxCopyValue = ({
  child,
  control,
  flag,
  getValues,
  hide,
  id,
  isMandatory,
  label,
  parent,
  path,
  register,
  resetField,
  setValue,
  watch,
  width,
  ...props
}) => {
  const [checked, setChecked] = useState(false)
  const [payload, setPayload] = useState()

  const dispatch = useDispatch()
  const onCheck = (checked) => {
    setChecked(checked)
  }

  useEffect(() => {
    const sub = watch(parent)
    if (checked && sub && sub !== '') {
      var param = []
      for (let i = 0; i < parent.length; i++) {
        param.push({
          fieldId: parent[i],
          value: getValues(parent[i]),
        })
      }
      const payload = {
        reference: id,
        flag: flag,
        param: param,
      }
      setPayload(payload)
    }
  }, [checked, watch, setPayload])

  useEffect(() => {
    if (checked && payload) {
      getDataActionWithButton(path, payload)
        .then((res) => {
          dispatch(resetDropdown())
          const data = res.data.data
          let dataArray = []
          for (let i = 0; i < data.length; i++) {
            if (data[i].value && data[i].value !== '') {
              resetField(data[i].fieldId)
              setValue(data[i].fieldId, data[i].value)
            }
            if (data[i].valueList && data[i].valueList.length > 0) {
              for (let j = 0; j < data[i].valueList.length; j++) {
                if (
                  data[i].valueList[j].value &&
                  data[i].valueList[j].value !== ''
                ) {
                  const dropdown = {
                    id: data[i].fieldId,
                    parentId: data[i].parenId,
                    valueList: data[i].valueList,
                  }
                  dataArray = [...dataArray, dropdown]
                }
              }
            }
            dispatch(setDropdown({ data: dataArray }))
          }
        })
        .catch((err) => {
          window.Swal.fire(
            'Peringatan',
            `Terjadi kesalahan pada server`,
            'warning'
          )
        })
    }
  }, [checked, payload])

  return (
    <>
      <div className="form-check">
        <input
          id={id}
          className="form-check-input"
          type="checkbox"
          value={checked}
          onChange={(e) => onCheck(e.target.checked)}
        />
        <label className="form-check-label">{label}</label>
      </div>
    </>
  )
}

export default CheckboxCopyValue
